@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

.wallet-adapter-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border-radius: 4px;
}

.wallet-adapter-button-trigger {
    background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: white;
}

.wallet-adapter-button:not([disabled]):hover {
    background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.wallet-adapter-button-end-icon {
    margin-left: 12px;
}

.wallet-adapter-button-start-icon {
    margin-right: 12px;
}

.wallet-adapter-collapse {
    width: 100%;
}

.wallet-adapter-dropdown {
    position: relative;
    display: inline-block;
}

.wallet-adapter-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.wallet-adapter-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: #fff;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
    align-self: center;
    fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
    transform: rotate(180deg);
    transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1;
}

.wallet-adapter-modal-button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 12px;
    cursor: pointer;
    background: #1a1f2e;
    border: none;
    border-radius: 50%;
}

.wallet-adapter-modal-button-close:focus-visible {
    outline-color: white;
}

.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
    fill: #fff;
}

.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wallet-adapter-modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin: 1rem;
        min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
    }
}

.wallet-adapter-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #10141f;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    flex: 1;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%;
}

.wallet-adapter-modal-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    padding: 64px 48px 48px 48px;
    text-align: center;
    color: #fff;
}

@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size: 18px;
    }
}

.wallet-adapter-modal-list {
    margin: 0 0 12px 0;
    padding: 0;
    width: 100%;
    list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
    font-weight: 400;
    border-radius: 0;
    font-size: 18px;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
    width: 28px;
    height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
    margin-left: auto;
    font-size: 14px;
    opacity: .6;
}

.wallet-adapter-modal-list-more {
    cursor: pointer;
    border: none;
    padding: 12px 24px 24px 12px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #fff;
}

.wallet-adapter-modal-list-more svg {
    transition: all 0.1s ease;
    fill: rgba(255, 255, 255, 1);
    margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
    transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 24px 24px;
    box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
    display: block;
    cursor: pointer;
    margin-top: 48px;
    width: 100%;
    background-color: #512da8;
    padding: 12px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    color: #fff;
}

.tiplink-connect-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity linear 150ms;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
  }
  
  .tiplink-connect-modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 480px) {
    .tiplink-connect-modal-container {
      margin: 1rem;
      min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
    }
  }
  
  .tiplink-connect-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1050;
    max-width: 360px;
    border-radius: 10px;
    background: white;
    color: #2d4c5d;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    font-family: "Inter", sans-serif;
    flex: 1;
    padding: 32px;
  }
  
  @media (prefers-color-scheme: dark) {
    .tiplink-connect-modal-wrapper {
      background-color: #17303e;
      color: white;
    }
  }

  .tiplink-connect-modal-light {
    background: white;
    color: #2d4c5d; 
  }

  .tiplink-connect-modal-dark {
    background-color: #17303e;
    color: white;
  }
  
  .tiplink-connect-modal-wrapper {
    width: 100%;
  }
  
  .tiplink-connect-modal-fade-in {
    opacity: 1;
  }
  
  .tiplink-connect-modal-dapp-logo {
    border-radius: 50%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    width: 80px;
    height: 80px;
  }
  
  .tiplink-connect-google-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 4px;
    padding: 8px;
  }
  
  .tiplink-connect-modal-title {
    margin-top: 12px;
    font-size: 26px;
  }
  
  .tiplink-connect-login-with-google {
    display: flex;
    flex-direction: row;
    background-color: #007cbf;
    border-radius: 8px;
    padding: 4px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    align-items: center;
    margin-top: 24px;
  }
  
  .tiplink-connect-login-with-google:hover {
    background-color: #006399;
    cursor: pointer;
  }
  
  .tiplink-connect-login-with-google-text {
    text-align: center;
    justify-content: center;
    width: 100%;
    color: white;
  }
  
  .tiplink-connect-powered-by {
    color: #6b818c;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 8px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  
  .tiplink-connect-modal-tiplink-logo {
    margin-left: 4px;
    cursor: pointer;
  }
  
  .tiplink-connect-or {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    color: #99aab3;
    font-weight: bold;
    font-size: 12px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  
  .tiplink-connect-or::before,
  .tiplink-connect-or::after {
    content: "";
    flex: 1;
    width: 130px;
    border-bottom: 1px solid #e0e7eb; /* Adjust the color and size as needed */
    margin: 0 10px; /* Adjust spacing around the text */
  }
  
  .tiplink-connect-installed-wallets {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
    width: 100%;
  }
  
  .tiplink-connect-wallet-icon {
    border-radius: 50%;
    padding: 12px;
    width: 44px;
    height: 44px;
    border: none;
    cursor: pointer;
    background-color: rgba(0, 48, 82, 0.04);
  }
  
  .tiplink-connect-wallet-icon:hover {
    background-color: rgba(0, 48, 82, 0.1);
  }
  
  @media (prefers-color-scheme: dark) {
    .tiplink-connect-wallet-icon {
      background-color: #2d4c5d;
    }
    .tiplink-connect-wallet-icon:hover {
      background-color: #526b79;
    }
  }

  .tiplink-connect-modal-light-button {
    background-color: rgba(0, 48, 82, 0.04); 
  }

  .tiplink-connect-modal-light-button:hover {
    background-color: rgba(0, 48, 82, 0.1);
  }

  .tiplink-connect-modal-dark-button {
    background-color: #2d4c5d; 
  }

  .tiplink-connect-modal-dark-button:hover {
    background-color: #526b79;
  }
  
  .tiplink-connect-wallet-icon img {
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    width: 20px;
    height: 20px;
  }
  

.tiplink-connect-login-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
    border: none;
    background-color: #007cbf;
    color: white;
    gap: 4px;
  }
  
  .tiplink-connect-login-button:hover {
    cursor: pointer;
    background-color: #006399;
  }
  
  .tiplink-connect-login-button-rectangle {
    border-radius: 8px;
  }
  
  .tiplink-connect-login-button-round {
    border-radius: 999px;
  }
  
  .tiplink-connect-login-button-blue {
    background-color: #007cbf;
    color: white;
  }
  
  .tiplink-connect-login-button-blue:hover {
    background-color: #006399;
  }
  
  .tiplink-connect-login-button-black {
    background-color: #17303e;
    color: white;
  }
  
  .tiplink-connect-login-button-black:hover {
    background-color: #2d4c5d;
  }
  
  .tiplink-connect-login-button-white {
    background-color: white;
    color: #007cbf;
  }
  
  .tiplink-connect-login-button-white:hover {
    background-color: #f2f8fc;
  }
  
  .tiplink-connect-login-button-tinted-blue {
    background-color: #006399;
    color: white;
  }
  
  .tiplink-connect-login-button-tinted-blue:hover {
    background-color: #007cbf;
  }
  
  .tiplink-connect-login-button-tinted-black {
    background-color: #2d4c5d;
    color: white;
  }
  
  .tiplink-connect-login-button-tinted-black:hover {
    background-color: #17303e;
  }
  
  .tiplink-connect-login-button-tinted-white {
    background-color: #f2f8fc;
    color: #007cbf;
  }
  
  .tiplink-connect-login-button-tinted-white:hover {
    background-color: white;
  }
 
  .tiplink-connect-login-dropdown {
    position: relative;
    display: inline-block;
  }

  .tiplink-connect-login-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
    font-family: "Inter", sans-serif;
}

.tiplink-connect-login-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
    width: 100%;
}

.tiplink-connect-login-dropdown-list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: #fff;
}

.tiplink-connect-login-dropdown-list-item:not([disabled]):hover {
    background-color: #1a1f2e;
}
